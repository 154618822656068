<template>

	<div>
		<rxNavBar class ="aa" title="优惠券" androidOrIOSFlag="true"></rxNavBar>
		<!-- 顶部固定区域-->
		<div class="header">
			<!-- 预约切换区域-->
			<div class="choose">
				<div class="choose-one">
					<div :class="condition?'choose-one-textActive':'choose-one-text'" @click="showOnOrder">未使用</div>
					<div class="choose-one-icon" v-show="condition"></div>
				</div>
				<div class="choose-line"></div>
				<div class="choose-one">
					<div :class="!condition?'choose-one-textActive':'choose-one-text'" @click="showFinished">已使用</div>
					<div class="choose-one-icon" v-show="!condition"></div>
				</div>
			</div>
		</div>
		<!-- 主体的列表们 -->
		<van-list v-model="loading" :finished="finished" finished-text="" @load="onLoad">
			<!-- 未使用 -->
			<div class="bo-dy" v-if="couponStatus == 0">
				<div class="lists" v-for=" (item,i) in couponList " :key="i" @click="discountAction(item.userCouponId,item.couponType,item.couponMoney,item.discount,item.couponEndTime)">
					<img class="liwu" src="../../../assets/images/liwu.png"></img>
					<img class="coupon" src="../../../assets/images/coupon.png"></img>
					<div class="title">{{item.couponName}}</div>
					<!-- <div class="useplace">使用门店：大连海米之家</div> -->
					<div class="time" v-if="item.couponStatus == 0 && (item.couponBeginTime && item.couponEndTime)">有效期：{{item.couponBeginTime}} 至 {{item.couponEndTime}} </div>
					<div class="time" v-if="item.couponStatus == 1">已使用 </div>
					<div class="time" v-if="item.couponStatus == 2">已过期 </div>
					<div class="smalltitle">{{item.couponType}}</div>
					<div class="money" v-if="item.couponType=='抵值券'">¥{{item.couponMoney}}</div>
					<div class="money" v-if="item.couponType=='折扣券'">{{Number(item.discount*10).toFixed(2)}}折</div>
				</div>
			</div>
			<!-- 已使用 -->
			<div class="bo-dy" v-if="couponStatus == 1">
				<div class="lists" v-for=" (item,i) in couponList " :key="i">
					<img class="liwu" src="../../../assets/images/liwu.png"></img>
					<img class="coupon" src="../../../assets/images/coupon1.png"></img>
					<div class="title2">{{item.couponName}}</div>
					<!-- <div class="useplace">使用门店：大连海米之家</div> -->
					<div class="time" v-if="item.couponStatus == 0 && (item.couponBeginTime && item.couponEndTime)">有效期：{{item.couponBeginTime}} 至 {{item.couponEndTime}} </div>
					<div class="time" v-if="item.couponStatus == 1">已使用 </div>
					<div class="time" v-if="item.couponStatus == 2">已过期 </div>
					<div class="smalltitle2">{{item.couponType}}</div>
					<div class="money2" v-if="item.couponType=='抵值券'">¥{{item.couponMoney}}</div>
					<div class="money2" v-if="item.couponType=='折扣券'">{{Number(item.discount*10).toFixed(2)}}折</div>
				</div>
			</div>
		</van-list>

		<!--空状态显示-->
		<common-empty v-if="isEmptuyFlag"></common-empty>
		<div style="height: 30px;width: 100%;"></div>
	</div>

</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
	} from "vant";
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
		queryBaseData,
		userCouponList
	} from "../../../getData/getData";
	import {
		getUserId,
		globaluserId,
		responseUtil
	} from "../../../libs/rongxunUtil";
	import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../../components/rongxun/rx-dialMobile/dialMobile'

	export default {
		name: "myOrder",
		data() {
			return {
				fromType:'',    //原生选择优惠券标记
				columns: [{
					dictionaryTitle: "全部",
					dictionaryValue: "0"
				}],
				show: false,
				showPicker: false,
				condition: true, //用来控制两种状态的切换条件
				isOccupationShow: false,
				loading: false,
				finished: false,
				currentPage: 1, //当前页
				pageCount: '', //总条数
				numberPage: 10, //每页条数
				count: 0,
				certificateType: '',
				defaultIndex: '',
				isLoading: false,
				totalPage: '',
				couponStatus: 0, //传入时的优惠券状态(0 未使用 1 已使用 2 已过期)
				couponList: [], //优惠券列表
				isEmptuyFlag: false,
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
			dialMobile,
			rxNavBar
		},
		created() {
			this.initData();
			// 原生选择优惠券传参type 1
			this.fromType = this.$route.query.type

			// this.initDicData();
		},
		//初始化
		mounted() {},
		methods: {
			//初始化数据
			initData() {
				const that = this
				let initData = {
					user_id: globaluserId()
				}
			},
			//优惠券接口
			userCouponList() {
				const that = this
				if (that.dictionaryValue == 0) {
					that.dictionaryValue = ""
				}

				let initData = {}
				initData.user_id = globaluserId() //userid
				// alert(initData.user_id)
				initData.couponStatus = that.couponStatus //合同类型(0 租客 1 业主)
				initData.currentPage = that.currentPage //当前页
				initData.numberPage = that.numberPage //每页条数
				userCouponList(initData).then(function(response) {
					responseUtil.dealResponse(that, response, () => {
						that.pageCount = response.data.data.pageCount //总条数
						var totalPage = response.data.data.totalPage //总页数
						var list = response.data.data.userCouponList
						debugger
						//总条数 为0的话 显示空状态页面
						if (response.data.data.pageCount == 0) {
							that.isEmptuyFlag = true
						}else{
							that.isEmptuyFlag = false
						}
						//如果没有数据 限制加载
						if (totalPage == 0 || that.pageCount == 0 || !list) { //没有数据时
							that.loading = false
							that.finished = true
						}

						if (list && that.pageCount != 0) { //有数据时
							var currentPage = parseInt(that.currentPage); //当前页
							if (totalPage >= currentPage) { //当前页不大于总页数
								that.currentPage = currentPage + 1
								for (let i = 0; i < list.length; i++) {
									that.couponList.push(list[i])
								}
								that.loading = false
							} else {
								that.loading = false
								that.finished = true
							}
						}
					})
				})
			},
			//未使用
			showOnOrder() {
				var that = this
				that.condition = true
				that.couponStatus = 0
				that.couponList = []
				this.default();
				this.onLoad();
			},
			//已使用
			showFinished() {
				var that = this
				that.condition = false
				that.couponStatus = 1
				that.couponList = []
				this.default();
				this.onLoad();
			},
			//返回上一页
			leftReturn() {
				this.$router.go(-1)
			},
			//分页
			onLoad() {
				this.userCouponList();
			},
			default () { //默认值
				this.pageCount = ''
				this.currentPage = '1'
			},
			discountAction(userCouponId, couponType, couponMoney, discount, couponEndTime) {
				var date = new Date();
				var seperator1 = "-";
				var month = date.getMonth() + 1;
				var strDate = date.getDate();
				if (month >= 1 && month <= 9) {
					month = "0" + month;
				}
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				var currentdate = date.getFullYear() + seperator1 + month + seperator1 + strDate;
				if(couponEndTime < currentdate){
					Toast('当前优惠券已过期')
				}
				// console.log(couponEndTime + "优惠券截止日期")
				// console.log(currentdate + "当前日期")

				// if (couponType == '抵值券') {
				// 	console.log(couponMoney)
				// }
				// if (couponType == '折扣券') {
				// 	console.log(discount)
				// }
			},
		},

	}
</script>

<style scoped>
	.navbar {
		background-color: white;
		border-bottom: 2px solid white;
	}

	.header {
		width: 100%;
		height: auto;
		position: fixed;
		z-index: 8;
		top: 52px;
		background-color: #f8f8f8;
	}

	.choose {
		width: 100%;
		height: auto;
		background-color: white;
		display: flex;
	}

	.choose-one {
		width: 187px;
		text-align: center;
	}

	.choose-one-textActive {
		width: 42px;
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		font-weight: bold;
		margin-top: 24px;
		margin-left: 72px;
		color: rgba(255, 93, 59, 1);
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.choose-one-text {
		width: 42px;
		height: 20px;
		line-height: 20px;
		font-size: 14px;
		margin-top: 24px;
		margin-left: 72px;
		color: rgba(153, 153, 153, 1);
		text-align: center;
		font-family: PingFangSC-Semibold;
	}

	.choose-one-icon {
		width: 20px;
		height: 4px;
		border-radius: 3px;
		background-color: rgba(255, 93, 59, 1);
		margin: 5px 0 15px 83px;
	}

	.choose-line {
		width: 2px;
		height: 17px;
		margin-top: 23px;
		background-color: rgba(239, 239, 239, 1);
	}

	.empty {
		margin-top: 250px;
		text-align: center;
	}

	.empty img {
		width: 85px;
	}

	.empryText {
		text-align: center;
		color: #ACACAC;
		color: rgba(172, 172, 172, 1);
		font-size: 12px;
		margin-top: 50px;
	}

	.bo-dy {
		margin-top: 80px;
	}

	.lists {
		position: relative;
		background: url(../../../assets/images/bak.png) no-repeat;
		background-size: cover;
		height: 80px;
		width: 90%;
		margin: 0 auto;
		margin-bottom: 20px;
		border-bottom: 1px solid rgb(228,228,228);
	}

	.liwu {
		position: absolute;
		bottom: 0px;
		right: 10px;
		width: 45px;
		height: 45px;
	}

	.coupon {
		position: absolute;
		top: 20px;
		left: 20px;
		width: 15px;
		height: 15px;
	}

	.title,
	.title2 {
		font-size: 15px;
		font-family: PingFangSC-Semibold;
		font-weight: 600;
		float: left;
		margin-left: 45px;
		margin-top: 16px;
		width: 210px;
		overflow: hidden;
		text-overflow:ellipsis;
		white-space: nowrap;
	}

	.title2 {
		color: #999999;
	}

	.useplace {
		color: rgba(153, 153, 153, 1);
		font-size: 12px;
		font-family: PingFangSC-Regular;
		position: absolute;
		left: 20px;
		top: 45px;
	}

	.time {
		color: rgba(153, 153, 153, 1);
		font-size: 10px;
		font-family: PingFangSC-Regular;
		position: absolute;
		bottom: 12px;
		left: 20px;
	}

	.smalltitle,
	.smalltitle2 {
		color: rgba(34, 34, 34, 1);
		font-size: 13px;
		font-family: PingFangSC-Semibold;
		font-weight: 600;
		position: absolute;
		top: 30px;
		right: 25px;
	}

	.smalltitle2 {
		color: #999999;
	}

	.money,
	.money2 {
		color: rgba(255, 93, 59, 1);
		font-size: 15px;
		font-family: Oswald-Regular;
		font-weight: 600;
		position: absolute;
		right: 28px;
		top: 50px;
	}

	.money2 {
		color: #999999;
	}
</style>
